import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://7944495ba280454c80e73647c55b8cbe@o617827.ingest.sentry.io/5751258",
  release: "60f4ea9a2ebfe3df96a0d79b0585c4e7ac5ec6fa",
  environment: "production",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
});
